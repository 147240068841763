export const provinces = [
    { value: "0000", label: "Semua" },
    { value: "0100", label: "ACEH" },
    { value: "0200", label: "SUMATERA UTARA" },
    { value: "0300", label: "SUMATERA BARAT" },
    { value: "0400", label: "R I A U" },
    { value: "0500", label: "J A M B I" },
    { value: "0600", label: "SUMATERA SELATAN" },
    { value: "0700", label: "BENGKULU" },
    { value: "0800", label: "LAMPUNG" },
    { value: "0900", label: "DKI JAKARTA" },
    { value: "1000", label: "JAWA BARAT" },
    { value: "1100", label: "JAWA TENGAH" },
    { value: "1200", label: "D.I. YOGYAKARTA" },
    { value: "1300", label: "JAWA TIMUR" },
    { value: "1400", label: "B A L I" },
    { value: "1500", label: "NUSA TENGGARA BARAT" },
    { value: "1600", label: "NUSA TENGGARA TIMUR" },
    { value: "1700", label: "KALIMANTAN BARAT" },
    { value: "1800", label: "KALIMANTAN TENGAH" },
    { value: "1900", label: "KALIMANTAN SELATAN" },
    { value: "2000", label: "KALIMANTAN TIMUR" },
    { value: "2100", label: "SULAWESI UTARA" },
    { value: "2200", label: "SULAWESI TENGAH" },
    { value: "2300", label: "SULAWESI SELATAN" },
    { value: "2400", label: "SULAWESI TENGGARA" },
    { value: "2500", label: "MALUKU" },
    { value: "2600", label: "PAPUA" },
    { value: "2700", label: "BANGKA BELITUNG" },
    { value: "2800", label: "BANTEN" },
    { value: "3500", label: "GORONTALO" },
    { value: "3600", label: "MALUKU UTARA" },
    { value: "3700", label: "KEPULAUAN RIAU" },
    { value: "3800", label: "SULAWESI BARAT" },
    { value: "3900", label: "PAPUA BARAT" },
    { value: "4000", label: "KALIMANTAN UTARA" }
];

export default provinces;
