import React, { useEffect, useState } from "react";

const SelectOptions = ({ options, onChange }) => {
    const [inputValue, setInputValue] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [showDropdown, setShowDropdown] = useState(false);

    const handleInputChange = (e) => {
        const value = e.target.value;
        onChange(value);
        setInputValue(value);
        setFilteredOptions(
            options.filter((option) =>
                option.label.toLowerCase().includes(value.toLowerCase())
            )
        );
    };

    const handleSelect = (option) => {
        setInputValue(option.label);
        setShowDropdown(false);
        if (onChange) {
            onChange(option.value)
        }
    };

    const handleFocus = () => {
        setShowDropdown(true);
    };

    const handleBlur = () => {
        setTimeout(() => setShowDropdown(false), 200); // Delay to allow click selection
    };

    return (
        <div className="relative w-full">
            <input
                autoComplete="off"
                id="default-input"
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={inputValue}
                placeholder="Pilih provinsi"
                className="text-md mr-4 block w-full border border-gray-300 bg-gray-50 p-2.5 text-sm font-normal text-fontPrimary focus:border-primary focus:outline-none focus:ring-primary"
            />
            {showDropdown && filteredOptions.length > 0 && (
                <ul className="absolute left-0 z-10 max-h-[200px] overflow-auto top-full mt-1 w-full border border-gray-300 bg-white shadow-md">
                    {filteredOptions.map((option) => (
                        <li
                            key={option.value}
                            onClick={() => handleSelect(option)}
                            className="cursor-pointer p-2 hover:bg-gray-200 text-fontPrimary font-normal"
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SelectOptions;
